// -------------------------------------------- select options tickers appear in manual add trade --------------------------------------------------
export const tickerArrays = [
  [ // Futures
    { "label": "E-mini S&P 500 Futures", "year": "ES", "contract_size": 50, "tick_size": 0.25 },
    { "label": "E-mini Nasdaq 100 Futures", "year": "NQ", "contract_size": 20, "tick_size": 0.25 },
    { "label": "E-mini Dow Jones Futures", "year": "YM", "contract_size": 5, "tick_size": 1 },
    { "label": "Crude Oil Futures", "year": "CL", "contract_size": 1000, "tick_size": 0.01 },
    { "label": "Gold Futures", "year": "GC", "contract_size": 100, "tick_size": 0.10 },
    { "label": "Silver Futures", "year": "SI", "contract_size": 5000, "tick_size": 0.005 },
    { "label": "Natural Gas Futures", "year": "NG", "contract_size": 10000, "tick_size": 0.001 },
    { "label": "Euro FX Futures", "year": "6E", "contract_size": 125000, "tick_size": 0.0001 },
    { "label": "British Pound Futures", "year": "6B", "contract_size": 62500, "tick_size": 0.0001 },
    { "label": "Japanese Yen Futures", "year": "6J", "contract_size": 12500000, "tick_size": 0.000001 },
    { "label": "Eurodollar Futures", "year": "GE", "contract_size": 1000000, "tick_size": 0.0025 },
    { "label": "Russell 2000 E-Mini Futures", "year": "RTY", "contract_size": 50, "tick_size": 0.10 },
    { "label": "Australian Dollar Futures", "year": "6A", "contract_size": 100000, "tick_size": 0.0001 },
    { "label": "Canadian Dollar Futures", "year": "6C", "contract_size": 100000, "tick_size": 0.0001 },
    { "label": "Cocoa Futures", "year": "CC", "contract_size": 10, "tick_size": 1 },
    { "label": "Coffee Futures", "year": "KC", "contract_size": 37500, "tick_size": 0.05 },
    { "label": "Corn Futures", "year": "ZC", "contract_size": 5000, "tick_size": 0.25 },
    { "label": "Wheat Futures", "year": "ZW", "contract_size": 5000, "tick_size": 0.25 },
    { "label": "Soybeans Futures", "year": "ZS", "contract_size": 5000, "tick_size": 0.25 },
    { "label": "Soybean Oil Futures", "year": "ZL", "contract_size": 60000, "tick_size": 0.01 },
    { "label": "Soybean Meal Futures", "year": "ZM", "contract_size": 100, "tick_size": 0.10 },
    { "label": "Live Cattle Futures", "year": "LE", "contract_size": 40000, "tick_size": 0.025 },
    { "label": "Lean Hogs Futures", "year": "HE", "contract_size": 40000, "tick_size": 0.025 },
    { "label": "Coffee 'C' Futures", "year": "KC", "contract_size": 37500, "tick_size": 0.05 },
    { "label": "Cotton No. 2 Futures", "year": "CT", "contract_size": 50000, "tick_size": 0.01 },
    { "label": "Cocoa Futures", "year": "CC", "contract_size": 10, "tick_size": 1 },
    { "label": "Sugar No. 11 Futures", "year": "SB", "contract_size": 112000, "tick_size": 0.01 },
    { "label": "U.S. Dollar Index Futures", "year": "DX", "contract_size": 1000, "tick_size": 0.005 },
    { "label": "Swiss Franc Futures", "year": "6S", "contract_size": 125000, "tick_size": 0.0001 },
    { "label": "New Zealand Dollar Futures", "year": "6N", "contract_size": 100000, "tick_size": 0.0001 },
    { "label": "Copper Futures", "year": "HG", "contract_size": 25000, "tick_size": 0.0005 },
    { "label": "Palladium Futures", "year": "PA", "contract_size": 100, "tick_size": 0.05 },
    { "label": "Platinum Futures", "year": "PL", "contract_size": 50, "tick_size": 0.10 },
    { "label": "Gasoline Futures", "year": "RB", "contract_size": 42000, "tick_size": 0.0001 },
    { "label": "Heating Oil Futures", "year": "HO", "contract_size": 42000, "tick_size": 0.0001 },
    { "label": "Copper Futures", "year": "HG", "contract_size": 25000, "tick_size": 0.0005 },
    { "label": "Palladium Futures", "year": "PA", "contract_size": 100, "tick_size": 0.05 },
    { "label": "Platinum Futures", "year": "PL", "contract_size": 50, "tick_size": 0.10 },
    { "label": "Gasoline Futures", "year": "RB", "contract_size": 42000, "tick_size": 0.0001 },
    { "label": "Heating Oil Futures", "year": "HO", "contract_size": 42000, "tick_size": 0.0001 },
    //micro
    { "label": "Micro E-mini S&P 500 Futures", "year": "MES", "contract_size": 1, "tick_size": 0.25 },
    { "label": "Micro E-mini Nasdaq 100 Futures", "year": "MNQ", "contract_size": 1, "tick_size": 0.25 },
    { "label": "Micro E-mini Dow Jones Futures", "year": "MYM", "contract_size": 1, "tick_size": 1 },
    { "label": "Micro Crude Oil Futures", "year": "MCL", "contract_size": 100, "tick_size": 0.01 },
    { "label": "Micro Gold Futures", "year": "MGC", "contract_size": 10, "tick_size": 0.10 },
    { "label": "Micro Silver Futures", "year": "MSI", "contract_size": 1000, "tick_size": 0.005 },
    { "label": "Micro Natural Gas Futures", "year": "MNG", "contract_size": 1000, "tick_size": 0.001 },
    { "label": "Micro Euro FX Futures", "year": "M6E", "contract_size": 12500, "tick_size": 0.0001 },
    { "label": "Micro British Pound Futures", "year": "M6B", "contract_size": 6250, "tick_size": 0.0001 },
    { "label": "Micro Japanese Yen Futures", "year": "M6J", "contract_size": 12500000, "tick_size": 0.000001 }
  ],
  [ // Crypto
    { label: 'Bitcoin', year: 'BTC' },
    { label: 'Ethereum', year: 'ETH' },
    { label: 'Binance Coin', year: 'BNB' },
    { label: 'Cardano', year: 'ADA' },
    { label: 'XRP', year: 'XRP' },
    { label: 'Solana', year: 'SOL' },
    { label: 'Polkadot', year: 'DOT' },
    { label: 'Dogecoin', year: 'DOGE' },
    { label: 'Avalanche', year: 'AVAX' },
    { label: 'Terra', year: 'LUNA' },
    { label: 'VeChain', year: 'VET' },
    { label: 'Chainlink', year: 'LINK' },
    { label: 'Litecoin', year: 'LTC' },
    { label: 'Cosmos', year: 'ATOM' },
    { label: 'Bitcoin Cash', year: 'BCH' },
    { label: 'Filecoin', year: 'FIL' },
    { label: 'Tezos', year: 'XTZ' },
    { label: 'Stellar', year: 'XLM' },
    { label: 'Aave', year: 'AAVE' },
    { label: 'Compound', year: 'COMP' },
    { label: 'Synthetix', year: 'SNX' },
    { label: 'Polygon', year: 'MATIC' },
    { label: 'Algorand', year: 'ALGO' },
    { label: 'Neo', year: 'NEO' },
    { label: 'Ethereum Classic', year: 'ETC' },
    { label: 'Dash', year: 'DASH' },
    { label: 'Zcash', year: 'ZEC' },
    { label: 'Monero', year: 'XMR' },
    { label: 'IOTA', year: 'MIOTA' },
    { label: 'Theta', year: 'THETA' },
    { label: 'NEM', year: 'XEM' },
    { label: 'EOS', year: 'EOS' },
    { label: 'Tron', year: 'TRX' },
    { label: 'VeThor Token', year: 'VTHO' },
    { label: 'Kusama', year: 'KSM' },
    { label: 'BitTorrent', year: 'BTT' },
    { label: 'Huobi Token', year: 'HT' },
    { label: 'Decentraland', year: 'MANA' },
    { label: 'Zilliqa', year: 'ZIL' },
    { label: 'DigiByte', year: 'DGB' },
    { label: 'OMG Network', year: 'OMG' },
    { label: 'Waves', year: 'WAVES' },
    { label: 'Celo', year: 'CELO' },
    { label: 'Klaytn', year: 'KLAY' },
    { label: 'SushiSwap', year: 'SUSHI' },
    { label: 'Maker', year: 'MKR' },
    { label: 'Ren', year: 'REN' },
    { label: 'Chiliz', year: 'CHZ' },
    { label: 'Curve DAO Token', year: 'CRV' },
    { label: 'Siacoin', year: 'SC' },
    { label: 'Ontology', year: 'ONT' },
    { label: 'Synereo', year: 'AMP' },
    { label: 'Golem', year: 'GLM' },
    { label: 'Reserve Rights', year: 'RSR' },
    { label: '0x', year: 'ZRX' },
    { label: 'Horizen', year: 'ZEN' },
    { label: 'Enjin Coin', year: 'ENJ' },
    { label: 'Holo', year: 'HOT' },
  ],
  [ //Stocks
    { label: 'Apple Inc.', year: 'AAPL' },
    { label: 'Microsoft Corporation', year: 'MSFT' },
    { label: 'Amazon.com Inc.', year: 'AMZN' },
    { label: 'Alphabet Inc. (Google)', year: 'GOOGL' },
    { label: 'Facebook Inc.', year: 'META' },
    { label: 'Tesla Inc.', year: 'TSLA' },
    { label: 'NVIDIA Corporation', year: 'NVDA' },
    { label: 'Netflix Inc.', year: 'NFLX' },
    { label: 'PayPal Holdings Inc.', year: 'PYPL' },
    { label: 'Visa Inc.', year: 'V' },
    { label: 'Mastercard Incorporated', year: 'MA' },
    { label: 'JPMorgan Chase & Co.', year: 'JPM' },
    { label: 'Bank of America Corporation', year: 'BAC' },
    { label: 'Walmart Inc.', year: 'WMT' },
    { label: 'Johnson & Johnson', year: 'JNJ' },
    { label: 'Procter & Gamble Company', year: 'PG' },
    { label: 'Verizon Communications Inc.', year: 'VZ' },
    { label: 'AT&T Inc.', year: 'T' },
    { label: 'Intel Corporation', year: 'INTC' },
    { label: 'Cisco Systems Inc.', year: 'CSCO' },
    { label: 'Coca-Cola Company', year: 'KO' },
    { label: 'Pfizer Inc.', year: 'PFE' },
    { label: 'Walt Disney Company', year: 'DIS' },
    { label: 'McDonald\'s Corporation', year: 'MCD' },
    { label: 'General Electric Company', year: 'GE' },
    { label: 'IBM', year: 'IBM' },
    { label: 'Visa Inc.', year: 'V' },
    { label: 'Johnson & Johnson', year: 'JNJ' },
    { label: 'Walmart Inc.', year: 'WMT' },
    { label: 'The Home Depot Inc.', year: 'HD' },
    { label: 'Intel Corporation', year: 'INTC' },
    { label: 'Cisco Systems Inc.', year: 'CSCO' },
    { label: 'Boeing Co.', year: 'BA' },
    { label: 'Oracle Corporation', year: 'ORCL' },
    { label: 'General Motors Company', year: 'GM' },
    { label: 'Ford Motor Company', year: 'F' },
    { label: 'Caterpillar Inc.', year: 'CAT' },
    { label: '3M Company', year: 'MMM' },
    { label: 'American Express Company', year: 'AXP' },
    { label: 'The Goldman Sachs Group Inc.', year: 'GS' },
    { label: 'Johnson & Johnson', year: 'JNJ' },
    { label: 'Verizon Communications Inc.', year: 'VZ' },
    { label: 'Chevron Corporation', year: 'CVX' },
    { label: 'Exxon Mobil Corporation', year: 'XOM' },
    { label: 'The Coca-Cola Company', year: 'KO' },
    { label: 'The Walt Disney Company', year: 'DIS' },
    { label: 'Boeing Co.', year: 'BA' },
    { label: 'Pfizer Inc.', year: 'PFE' },
    { label: 'Johnson & Johnson', year: 'JNJ' },
    { label: 'The Goldman Sachs Group Inc.', year: 'GS' },
    { label: 'The Home Depot Inc.', year: 'HD' },
    { label: 'JPMorgan Chase & Co.', year: 'JPM' },
    { label: 'The Procter & Gamble Company', year: 'PG' },
    { label: 'Intel Corporation', year: 'INTC' },
    { label: 'Visa Inc.', year: 'V' },
    { label: 'Walmart Inc.', year: 'WMT' },
    { label: 'The Boeing Company', year: 'BA' },
    { label: 'The Goldman Sachs Group Inc.', year: 'GS' },
    { label: 'The Home Depot Inc.', year: 'HD' },
    { label: 'Honeywell International Inc.', year: 'HON' },
    { label: 'Caterpillar Inc.', year: 'CAT' },
    { label: 'UnitedHealth Group Incorporated', year: 'UNH' },
    { label: 'Johnson & Johnson', year: 'JNJ' },
  ]

];



