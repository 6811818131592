export const AlertsMessages = [
    {
        ContentText: "you exceed your limit trades per day ",
        msgText: "Number of trades for today:",
        limit: "Your limit trades :"
    },
    {
        ContentText: "you lose a certain number of times in a row for today.",
        msgText: "Number of lose for today:",
        limit: "Your lose limit trades:"
    },
];


export const ALERTS_TYPE = { OVER_TRADING_ALERT: 0, LOSSING_TRADE_IN_ROW: 1 };